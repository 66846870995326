<template width="400" max-height="300">
  <vue-perfect-scrollbar class="chat-room--scrollbar" v-bind:style="editFilterStyle">
    <v-list raised>
      <v-list-item-group multiple>

        <v-list-item id="filterItemHeight">
          <v-checkbox color="primary" @change="sinceUpdated" label="Since" v-model="isSince"></v-checkbox>
          <v-card v-if="isSince" flat>
            <v-container fluid>
              <v-row>
                <v-col style="margin: 0px 0px 5px 10px">
                  <v-menu :disabled="!isSince" v-model="sinceDateMenu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field :disabled="!isSince" label="  Choose a Date" prepend-inner-icon="mdi-calendar"
                        readonly :value="sinceDateVal" v-on="on" @change="sinceDateUpdated"></v-text-field>
                    </template>
                    <v-date-picker v-model="sinceDateVal" no-title @input="sinceDateMenu = false"
                      @change="sinceDateUpdated"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu :disabled="!isSince" v-model="sinceTimeMenu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field :disabled="!isSince" label="  Choose Time" prepend-inner-icon="mdi-clock" readonly
                        :value="sinceTimeVal" v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-model="sinceTimeVal" format="24hr" @input="sinceTimeMenu = true"
                      @click:hour="sinceTimeUpdated" @click:minute="sinceTimeUpdated" @change="sinceTimeUpdated">
                    </v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-list-item>

        <v-list-item id="filterItemHeight">
          <v-checkbox color="primary" @change="untilUpdated" label="Until" v-model="isUntil"></v-checkbox>
          <v-card v-if="isUntil" flat>
            <v-container fluid>
              <v-row>
                <v-col style="margin-left: 20px">
                  <v-menu :disabled="!isUntil" v-model="untilDateMenu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field :disabled="!isUntil" label="  Choose a Date" prepend-inner-icon="mdi-calendar"
                        readonly :value="untilDateVal" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="untilDateVal" no-title @input="untilDateMenu = false"
                      @change="untilDateUpdated"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu :disabled="!isUntil" v-model="untilTimeMenu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field :disabled="!isUntil" label="  Choose Time" prepend-inner-icon="mdi-clock" readonly
                        :value="untilTimeVal" v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-model="untilTimeVal" format="24hr" @input="untilTimeMenu = true"
                      @click:hour="untilTimeUpdated" @click:minute="untilTimeUpdated" @change="untilTimeUpdated">
                    </v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-list-item>

        <v-list-item id="filterItemHeight">
          <v-checkbox color="primary" @change="limitUpdated" label="Limit" v-model="isLimit"></v-checkbox>
          <v-card-text v-if="isLimit" class="sliderPaddingTop">
            <v-slider :disabled="!isLimit" v-model="limit" thumb-label="always" :thumb-size="20" :min="1" :max="editLimit" step="1" ticks="always"
               @change="limitValueUpdated">
            </v-slider>
          </v-card-text>
        </v-list-item>

        <v-list-item id="filterItemHeight">
          <v-checkbox color="primary" @change="getArchivedResultsUpdated" label="Get Archived Results"
            v-model="isGetArchivedResults"></v-checkbox>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </vue-perfect-scrollbar>
</template>

<script>
  import { mapState } from "vuex";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";

  export default {
    name: "bottombarmore",
    components: {
      VuePerfectScrollbar
    },
    data() {
      return {
        value: 1,
        isSince: false,
        isUntil: false,
        isLimit: false,
        isGetArchivedResults: false,
        sinceDateMenu: false,
        sinceDateVal: null,
        sinceTimeMenu: false,
        sinceTimeVal: null,
        untilDateMenu: false,
        untilDateVal: null,
        untilTimeMenu: false,
        untilTimeVal: null,
        limit: 20,
        editFilterStyle: {
          "min-height": "1vh",
          height: "26vh",
          "max-height": "26vh"
        }
      };
    },
    computed: {
      ...mapState({
        isFiltersSaveEnabled: state => state.edit.isFiltersSaveEnabled,
        areFiltersValid: state => state.edit.areFiltersValid,
        editLimit: state => state.edit.editFilterLimit
      })
    },
    mounted() {
      if (this.isFiltersSaveEnabled && localStorage["edits-filter-options"] && localStorage["edits-filter-options"] !== (null || undefined)) {
        let filterOptions = JSON.parse(localStorage["edits-filter-options"]);
        if (filterOptions !== undefined && Object.keys(filterOptions).length > 0) {
          if (filterOptions.hasOwnProperty("since")) {
            this.isSince = true;
            this.sinceDateVal = filterOptions.since.substring(0, filterOptions.since.indexOf('T'));
            this.sinceTimeVal = filterOptions.since.slice((filterOptions.since.indexOf('T') + 1), filterOptions.since.indexOf('Z'));
            this.$store.commit("edit/changeSince", filterOptions.since);
          } if (filterOptions.hasOwnProperty("until")) {
            this.isUntil = true;
            this.untilDateVal = filterOptions.until.substring(0, filterOptions.until.indexOf('T'));
            this.untilTimeVal = filterOptions.until.slice((filterOptions.until.indexOf('T') + 1), filterOptions.until.indexOf('Z'));
            this.$store.commit("edit/changeUntil", filterOptions.until);
          } if (filterOptions.hasOwnProperty("limit")) {
            this.$store.dispatch("edit/handleEditCount");
            this.isLimit = true;
            this.limit = filterOptions.limit;
            this.value = this.limit;
            this.$store.commit("edit/changeIsLimit", this.isLimit);
            this.$store.commit("edit/changeLimit", this.limit);
          } if (filterOptions.hasOwnProperty("isArchived")) {
            this.isGetArchivedResults = true;
            this.$store.commit("edit/changeGetArchivedResults", this.isGetArchivedResults);
          }
        }
      }
    },
    methods: {
      sinceUpdated(newValue) {
        this.isSince = newValue;
        if (!this.isSince) {
          this.$store.commit("edit/changeSince", null);
          if (!this.areFiltersValid) {
            this.sinceDateVal = null;
            this.sinceTimeVal = null;
            this.validateSinceUntil();
          }
        } else {
          if (this.sinceDateVal === null && this.sinceTimeVal === null) {
            let date = new Date();
            let yesterday = new Date(date.setDate(date.getDate()-1));
            this.sinceDateVal = yesterday.getFullYear() + "-" + ((yesterday.getMonth() + 1).toString().padStart(2, "0")) + "-" + (yesterday.getDate().toString().padStart(2, "0"));
            this.sinceTimeVal = (date.getHours().toString().padStart(2, "0")) + ":" + date.getMinutes().toString().padStart(2, "0");
            this.validateSinceUntil();
            if (this.areFiltersValid) {
              this.$store.commit("edit/changeSince", this.sinceDateVal + "T" + this.sinceTimeVal + "Z");
            }           
          }
        }        
      },
      sinceDateUpdated() {
        this.validateSinceUntil();
        if (this.areFiltersValid) {
          this.$store.commit("edit/changeSince", this.sinceDateVal + "T" + this.sinceTimeVal + "Z");
        }            
      },
      sinceTimeUpdated() {
        this.validateSinceUntil();
        if (this.areFiltersValid) {
          this.$store.commit("edit/changeSince", this.sinceDateVal + "T" + this.sinceTimeVal + "Z");
        }
      },
      untilUpdated(newValue) {
        this.isUntil = newValue;
        if (!this.isUntil) {
          this.$store.commit("edit/changeUntil", null);
          if (!this.areFiltersValid) {
            this.untilDateVal = null;
            this.untilTimeVal = null;
            this.validateSinceUntil();
          }
        } else {
          if (this.untilDateVal === null && this.untilTimeVal === null) {
            let date = new Date();
            this.untilDateVal = date.getFullYear() + "-" + ((date.getMonth() + 1).toString().padStart(2, "0")) + "-" + date.getDate().toString().padStart(2, "0");
            this.untilTimeVal = date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0") ;
            this.$store.commit("edit/changeUntil", this.untilDateVal + "T" + this.untilTimeVal + "Z");
          }
          this.validateSinceUntil();
          if (this.areFiltersValid) {
              this.$store.commit("edit/changeUntil", this.untilDateVal + "T" + this.untilTimeVal + "Z");
          } 
        }
      },
      untilDateUpdated() {
        this.validateSinceUntil();
        if (this.areFiltersValid) {
            this.$store.commit("edit/changeUntil", this.untilDateVal + "T" + this.untilTimeVal + "Z");
        }
      },
      untilTimeUpdated() {
        this.validateSinceUntil();
        if (this.areFiltersValid) {
          this.$store.commit("edit/changeUntil", this.untilDateVal + "T" + this.untilTimeVal + "Z");
        }
      },
      limitUpdated(newValue) {
        this.isLimit = newValue;
        this.$store.commit("edit/changeIsLimit", this.isLimit);
        if (this.isLimit) {
          this.$store.commit("edit/changeLimit", this.limit);
        }
      },
      limitValueUpdated(newValue) {
        this.limit = newValue;
        this.$store.commit("edit/changeLimit", this.limit);
      },
      getArchivedResultsUpdated(newValue) {
        this.isGetArchivedResults = newValue;
        this.$store.commit("edit/changeGetArchivedResults", this.isGetArchivedResults);
      },
      validateSinceUntil() {
        let since = null, until = null;
        if (this.sinceDateVal !== null && this.sinceTimeVal !== null) {
          since = this.sinceDateVal + "T" + this.sinceTimeVal + "Z"
        }
        if (this.untilDateVal !== null && this.untilTimeVal !== null) {
          until = this.untilDateVal + "T" + this.untilTimeVal + "Z"
        }
        this.$store.dispatch("edit/handleValidateSinceUntilFilters", 
            {currentDate: new Date(), since: since, until: until});
      }
    }
  };
</script>

<style>
  #filterItemHeight {
    height: 20px;
    max-height: 20px;
  }
  .sliderPaddingTop {
    padding-top: 50px;
  }
</style>